<template>
  <div>
    <titleBar
      :title="'Posting '"
      :img="bot?.avatar"
      back="auto"
      :showBackArrowDesktop="true"
      subtitle=""
      :centered="false"
      theme="white"
      :showSignup="false"
      containerClass="donotuse"
      :showDesktop="true"
      :showAfterScroll="0"
      :scrollTopOnClick="true"
      :inline="false"
      actionButtonStyle="round"
      actionButtonRoundBg="transparent"
      iconPack="fas"
    />

    <div class="sectionNA">
      <div class="containerNA NHAw800">
        <b-field horizontal label=" ">
          <header>
            <h2 class="title is-2">
              <span>Featured tasks </span>
            </h2>
          </header>
        </b-field>
        <hr />

        <!-- START

        <b-field horizontal>
          <b-switch v-model="bot.enableTasks" type="is-info">
            Offer user the option to 
            <b-tooltip type="is-dark" multilined label="Yup! Your chatbot will post on its own.">
              <i class="fal fa-question-circle" aria-hidden="true"></i>
            </b-tooltip>
          </b-switch>
        </b-field>-->

        <div v-if="true">
          <hr />
          <b-field horizontal>
            <span>
              <h3 class="title is-4">Tasks</h3>
              <p class="subtitle">
                Offer users pre-defined ways to interact with your bot. <br />
                These starting points might give a better undertanding of your bots to users.<br />
                Tasks will appear on your bots profile, similarily to "Stories" in other apps.
                <b-tooltip type="is-dark" multilined label="  .">
                  <i class="fal fa-question-circle" aria-hidden="true"></i>
                </b-tooltip>
              </p>
            </span>
          </b-field>

          <b-field horizontal>
            <b-button @click="addNewTask()" type="is-dark">Create a Task</b-button>
          </b-field>

          <hr />
          <div v-if="true">
            <div v-for="(task, index) in tasks" :key="index">
              <div v-if="!task.editing" class="box" style="margin-top: 10px">
                <b-button @click="editTask(index)" style="float: right">Edit</b-button>

                <em>{{ task.label || "Untitled new tasks" }}</em>
                <p style="color: #888; margin-top: 10px">{{ task.system || task.autoMessage || "example post" }}</p>
              </div>
              <div v-else>
                <div class="box edit-task" style="margin-top: 10px">
                  <b-button class="is-successNO" @click="saveTask(index)" style="float: right; margin-bottom: 10px">
                    Close</b-button
                  >
                  <div class="clear"></div>

                  <b-field horizontal label=" Name  ">
                    <b-input
                      size="is-"
                      placeholder="Paris tips"
                      v-model="task.label"
                      :loading="posting"
                      :disabled="posting"
                    ></b-input>
                  </b-field>

                  <b-field horizontal label="Image URL ">
                    <b-input
                      size="is-"
                      placeholder="https://myapp.com/img/logo.png"
                      v-model="task.image"
                      :loading="posting"
                      :disabled="posting"
                      type="url"
                    ></b-input>
                  </b-field>
                  <b-field horizontal v-if="task.image">
                    <img :src="task.image" style="width: 90px; height: 90px; border-radius: 50%" />
                  </b-field>

                  <b-field horizontal>
                    <b-switch v-model="task.sendAutoMessage" type="is-info">
                      Send a pre-defined message when conversation starts
                      <b-tooltip type="is-dark" multilined label="Yup! Your chatbot will post on its own.">
                        <i class="fal fa-question-circle" aria-hidden="true"></i>
                      </b-tooltip>
                    </b-switch>
                  </b-field>

                  <b-field horizontal label="Automated first message" v-if="task.sendAutoMessage">
                    <b-input
                      type="textarea"
                      placeholder="Hello my bro! What's your favourite pizza."
                      v-model="task.autoMessage"
                      :loading="posting || task.loading"
                      :disabled="posting || task.loading"
                    ></b-input>
                  </b-field>

                  <b-field horizontal label="Instruction" v-if="!task.sendAutoMessage">
                    <b-input
                      type="textarea"
                      placeholder="Optional, displayed to user when they start the conversation."
                      v-model="task.userInstruction"
                      :loading="posting || task.loading"
                      :disabled="posting || task.loading"
                    ></b-input>
                  </b-field>

                  <br />
                  <b-field horizontal label="System">
                    <b-input
                      type="textarea"
                      placeholder="Pick a random movie, or a random quote, or a book that is not in the top 100 famous. Convey the essence of the book/quote/movie in 5 emojis. Name the book/quote/movie, then show the emojis."
                      v-model="task.system"
                      :loading="posting || task.loading"
                      :disabled="posting || task.loading"
                    ></b-input>
                  </b-field>

                  <b-field horizontal label="Hidden user message">
                    <b-input
                      type="textarea"
                      placeholder=" Optional. Help steer the system in the right direction by providing a first message on behalf of user."
                      v-model="task.hiddenUserMessage"
                      :loading="posting || task.loading"
                      :disabled="posting || task.loading"
                    ></b-input>
                  </b-field>

                  <b-field horizontal label="Example">
                    <b-input
                      size="is-"
                      placeholder="The movie Parasite in 5 emojis: 👨‍👩‍👧‍👦🏠💰🐞🔪"
                      v-model="task.example"
                      :loading="posting || task.loading"
                      :disabled="posting || task.loading"
                    ></b-input>
                  </b-field>

                  <b-field horizontal label="Creativity">
                    <b-slider v-model="task.creativity" :min="1" style="max-width: 200px"></b-slider>
                  </b-field>

                  <b-field horizontal label="Frequency">
                    <b-slider v-model="task.occurencePcnt" :min="1" style="max-width: 200px"></b-slider>
                  </b-field>

                  <br />

                  <b-field horizontal label="test prompt">
                    <b-input
                      size="is-"
                      placeholder="Write a poem about pizzas please!"
                      v-model="task.testPrompt"
                      :loading="posting || task.loading"
                      :disabled="posting || task.loading"
                    ></b-input>
                  </b-field>

                  <b-button
                    class="is-warning is-small"
                    @click="getTaskSampleResponse(index)"
                    :loading="posting || task.loading"
                    style="float: left; margin-bottom: 20px; margin-right: 20px"
                  >
                    TRY IT</b-button
                  >

                  <div class="message is-warning" v-if="task.sampleResponse">
                    {{ task.sampleResponse }}
                  </div>
                  <div class="clear"></div>
                  <!-- 
                <b-field label="Name">
                  <b-input v-model="task.name"></b-input>
                </b-field>
                <b-field label="Description">
                  <b-input v-model="task.description"></b-input>
                </b-field>
                 -->
                </div>
              </div>
            </div>
            <br />
          </div>
          <br />
          <br />
          <b-field horizontal label="   ">
            <b-button type="is-info is-outlined" @click="save" :loading="posting" :disabled="posting"
              >Save changes</b-button
            >
          </b-field>
        </div>

        <!-- END     
    -->
        <b-field horizontal label="   ">
          <b-button type="is-info is-outlined" @click="save" :loading="posting" :disabled="posting"
            >Save changes</b-button
          >
        </b-field>

        <hr />
      </div>
    </div>
  </div>
</template>

<script>
//import Editor from "@/components/e/EditorV";

export default {
  //name: "readmeEditor",
  components: {
    //Editor,
  },
  props: {
    bot: {
      type: Object,
      default: null,
    },
    posting: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // readmeJson: defaultReadme, // "432432432",
    };
  },
  mounted() {
    //set tasks is not defined
    if (!this.bot.tasks) {
      this.bot.tasks = [];
    }
  },
  computed: {
    tasks() {
      return this.bot.tasks || [];
    },
  },
  methods: {
    save() {
      this.$emit("save");
    },

    editTask(index) {
      this.bot.tasks[index].editing = true;
    },
    saveTask(index) {
      this.bot.tasks[index].editing = false;
    },
    addNewTask() {
      var newTask = { name: "", description: "", editing: true, occurencePcnt: 50, creativity: 70 };
      this.bot.tasks.unshift(newTask);
    },

    getTaskSampleResponse(index) {
      // var task = this.bot.tasks[index];
      //task.loading = true;
      //   this.bot.tasks[index].prompt = 456765;
      this.$set(this.bot.tasks[index], "loading", true);
      var prompt = this.bot.tasks[index].testPrompt;
      var system = this.bot.tasks[index].system;

      var testOptions = {
        system: system,
        prompt: prompt, // "pick a random moovie and sumerize it in 5 emojis. name the movie, then followed by emojis. Make sure the selected movie is NOt in top100",
        creativity: this.bot.tasks[index].creativity,
        example: this.bot.tasks[index].example,
      };

      //TODO: update for TASKS
      window.API.generateBotPost(this.bot.handle, testOptions)
        .then((text) => {
          // task.sampleResponse = text;
          console.log(index, this.bot.tasks[index]);
          this.$set(this.bot.tasks[index], "loading", false);
          this.$set(this.bot.tasks[index], "sampleResponse", text);
          //  this.bot.tasks[index].sampleResponse = 214;
          // this.bot.tasks[index].loading = false;
          //returns a string!
        })
        .catch((e) => {
          this.$set(this.bot.tasks[index], "loading", false);
          //  this.$set(this.bot.tasks[index], "sampleResponse", "Error: " + e);
          //buefy snackbar
          this.$buefy.snackbar.open({
            message: "Problem connecting to the API. Please try again.",
            type: "is-dark",
            position: "is-bottom",
            actionText: "Close",
            delay: 5000,
          });
        });
    },
  },
};
</script>

<style></style>
